<template>
  <div id="preview">
    <v-dialog v-model="memberDocumentPrompt" persistent scrollable>
      <upload-selected-member-document
        :record="member"
        v-if="memberDocumentPrompt"
        :showDialog="memberDocumentPrompt"
        @hideDialog="hideMemberDocumentPrompt($event)"
      ></upload-selected-member-document>
    </v-dialog>
    <v-dialog v-model="memberPhotoPrompt" persistent scrollable>
      <upload-selected-member-photo
        :record="member"
        v-if="memberPhotoPrompt"
        :showDialog="memberPhotoPrompt"
        @hideDialog="hideMemberPhotoPrompt($event)"
      ></upload-selected-member-photo>
    </v-dialog>
    <v-dialog v-model="uploadDocumentPrompt" persistent max-width="70%">
      <add-lom-document
        pageTitle="Upload Nomination List"
        pageDescription="Upload Nomination List"
        :recordData="member"
        :addRecordPrompt="uploadDocumentPrompt"
        @hideAddRecordPrompt="hideUploadRecordPrompt"
        v-if="uploadDocumentPrompt"
      ></add-lom-document>
    </v-dialog>
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title
          ><span class="headline"
            >Assign {{ YearName }} {{ pageDescription }}
          </span></v-toolbar-title
        >

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-form
        ref="form1"
        v-model="valid1"
        lazy-validation
        v-on:submit.prevent="confirmAlert"
      >
        <v-card-text>
          <h6><span class="text-danger">*</span>indicates required field</h6>
          <v-container>
            <!-- <v-row wrap>
              <v-col cols="12" md="6">
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="AreaCodeOptionsLoading"
                  :items="AreaCodeOptions"
                  :rules="AreaCodeRules"
                  v-model="AreaCode"
                  label="Area"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="RegionCodeOptionsLoading"
                  :items="RegionCodeOptions"
                  :rules="RegionCodeRules"
                  v-model="RegionCode"
                  label="Region"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row> -->
            <v-row>
              <!-- <v-col cols="12" sm="12" md="4">
                <label>*Year</label>
                <v-autocomplete
                  @change="getZones"
                  :reduce="(option) => option.value"
                  :items="JciYearCodeOptions"
                  :rules="JciYearCodeRules"
                  :loading="JciYearCodeOptionsLoading"
                  v-model="JciYearCode"
                  hint="Select Year"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col> -->
              <v-col cols="12" sm="12" md="6">
                <h6>
                  <label><span class="text-danger">*</span>Select Member</label>
                </h6>
                <v-autocomplete
                  @change="getMemberDetails"
                  :reduce="(option) => option.value"
                  :loading="MemberIdOptionsLoading"
                  :items="MemberIdOptions"
                  :rules="MemberIdRules"
                  v-model="MemberId"
                  required
                  outlined
                  dense
                  clearable
                >
                  <template v-slot:selection="{ item, index }">
                    <img width="50" height="50" :src="item.photo" />{{
                      item.text
                    }}
                  </template>
                  <template v-slot:item="{ item }">
                    <img width="50" height="50" :src="item.photo" />{{
                      item.text
                    }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <div v-if="MemberId > 0">
              <v-row>
                <v-col cols="12" sm="12" md="12" class="text-center">
                  <img width="100" height="auto" :src="member.photo" />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <label>Member Name</label>
                  <h5>{{ member.member_name }}</h5>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <label>Membership Id</label>
                  <h5>{{ member.membership_id }}</h5>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <label>Mobile No</label>
                  <h5>{{ member.mobile_no }}</h5>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <label>Email Id</label>
                  <h5>{{ member.email_id }}</h5>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                  <label>Address</label>
                  <h5>{{ member.address_txt }}</h5>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <h4>Date of Birth</h4>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="member.member_dob"
                        label="From Date"
                        hint="Select a From Date"
                        persistent-hint
                        readonly
                        v-on="on"
                        background-color="#F3F6F9"
                        class="form-control form-control-lg form-control-solid"
                        validate-on-blur
                        solo
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="member.member_dob"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <h4>Profile Update</h4>
                  <v-chip
                    :color="`${
                      member.profile_updated == 1 ? '#0f9d58' : '#f4b400'
                    }`"
                    draggable
                    dark
                    >{{ member.profile_updated == 1 ? "Completed" : "Pending" }}
                  </v-chip>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <h4>Photo Update</h4>
                  <v-chip
                    :color="`${member.photo_flag ? '#0f9d58' : '#f4b400'}`"
                    draggable
                    dark
                    >{{ member.photo_flag ? "Completed" : "Pending" }}
                  </v-chip>
                  &nbsp;
                  <v-icon
                    icon
                    size="25px"
                    color="primary"
                    @click.prevent="memberPhotoPrompt = true"
                    v-if="member.photo_flag == false"
                  >
                    mdi-cloud-upload
                  </v-icon>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <h4>Age Proof Document Upload</h4>
                  <v-row wrap>
                    <v-col>
                      <v-chip
                        :color="`${
                          member.document_flag ? '#0f9d58' : '#f4b400'
                        }`"
                        draggable
                        dark
                        >{{ member.document_flag ? "Completed" : "Pending" }}
                      </v-chip>
                      &nbsp;
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click.prevent="memberDocumentPrompt = true"
                        v-if="member.document_flag == false"
                      >
                        mdi-cloud-upload
                      </v-icon>
                    </v-col>
                    <v-col>
                      <viewer
                        rebuild
                        :images="member.document_path_array"
                        v-if="member.document_flag == true"
                      >
                        <img
                          class="mr-4"
                          width="80"
                          height="80"
                          :src="src"
                          :key="i"
                          v-for="(src, i) in member.document_path_array"
                        />
                      </viewer>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <br />
              <v-divider />
              <br />
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <h3 class="text-dark text-center">
                    Directory Information Document
                  </h3>
                  <div v-if="member.lom_document_path_array.length > 0">
                    <h6
                      v-for="(row, index) in member.lom_document_path_array"
                      :key="index"
                    >
                      {{ index + 1 }}.
                      <a :href="row" target="_blank">
                        Download Directory Information Document {{ index + 1 }}
                      </a>
                    </h6>
                    <br />
                    <span class="text-h6">
                      Upload DIS Document &nbsp;
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click.prevent="uploadDocumentPrompt = true"
                      >
                        mdi-cloud-upload
                      </v-icon>
                    </span>
                  </div>
                  <div v-else>
                    <v-chip color="#f4b400" draggable dark>Pending </v-chip>
                    &nbsp;
                    <v-icon
                      icon
                      size="25px"
                      color="primary"
                      @click.prevent="uploadDocumentPrompt = true"
                    >
                      mdi-cloud-upload
                    </v-icon>
                  </div>
                </v-col>
              </v-row>
              <br />
              <v-row>
                <v-col cols="12" lg="12" sm="12" md="12">
                  <v-divider />
                  <label>
                    <h3><span class="text-danger">*</span> Reason</h3>
                  </label>
                  <v-textarea
                    outlined
                    v-model="Reason"
                    :rules="ReasonRules"
                    name="input-7-4"
                    label="Reason"
                    value=""
                  ></v-textarea>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" text @click="closePrompt">Close</v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="SubmitFlag"
            :disabled="!valid1"
            @click="confirmAlert"
            >Save</v-btn
          > -->
          <v-btn
            @click="closePrompt"
            elevation="30"
            shaped
            tile
            small
            color="#a52a2a"
            class="font-size-h6 px-5 mr-2 white--text"
          >
            Close
          </v-btn>
          <v-btn
            :disabled="!valid1"
            @click.prevent="confirmAlert"
            :loading="SubmitFlag"
            elevation="30"
            shaped
            tile
            small
            color="#1db954"
            class="font-size-h6 px-5 mr-2 white--text"
          >
            Approve
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
        <br />
      </v-form>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import UploadSelectedMemberDocument from "@/view/pages/members/UploadSelectedMemberDocument.vue";
import UploadSelectedMemberPhoto from "@/view/pages/members/UploadSelectedMemberPhoto.vue";
import AddLomDocument from "@/view/pages/erp/reports/AddLomDocument.vue";

export default {
  mixins: [common],
  components: {
    UploadSelectedMemberDocument,
    UploadSelectedMemberPhoto,
    AddLomDocument,
  },
  props: {
    editRecordPrompt: {
      type: Boolean,
      required: true,
    },
    recordIndex: {
      type: Number,
      required: true,
    },
    record: {
      type: Object,
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      member: {},

      memberDocumentPrompt: false,
      memberPhotoPrompt: false,

      rows: [],
      item: {},

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      AreaCodeRules: [(v) => !!v || "Area is required"],
      AreaCode: "",
      AreaCodeOptions: [],
      AreaCodeOptionsLoading: false,

      RegionCodeRules: [(v) => !!v || "Region is required"],
      RegionCode: "",
      RegionCodeOptions: [],
      RegionCodeOptionsLoading: false,

      MemberIdRules: [(v) => !!v || "Member is required"],
      MemberId: "",
      MemberIdOptions: [],
      MemberIdOptionsLoading: false,

      Reason: "",
      ReasonRules: [(v) => !!v || "Reason is required"],
      menu1: false,

      uploadDocumentPrompt: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
    },
    MemberIdOptions: function () {
      this.MemberIdOptionsLoading = false;
    },
    AreaCodeOptions: function () {
      this.AreaCodeOptionsLoading = false;
    },
    RegionCodeOptions: function () {
      this.RegionCodeOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    hideMemberDocumentPrompt(flag) {
      console.log("hideMemberDocumentPrompt called");
      console.log({ flag });
      this.memberDocumentPrompt = false;
      if (flag) {
        this.member.document_flag = true;
      }
    },
    hideMemberPhotoPrompt(flag) {
      console.log("hideMemberPhotoPrompt called");
      console.log({ flag });
      this.memberPhotoPrompt = false;
      if (flag) {
        this.member.photo_flag = true;
      }
    },
    hideUploadRecordPrompt(flag) {
      console.log("hideUploadRecordPrompt called");
      this.uploadDocumentPrompt = false;
      if (flag) {
        this.$emit("hideEditRecordPrompt", true);
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "lom_president",
        Action: "list_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      var recordIndex = this.recordIndex;
      console.log(
        "recordIndex=" + recordIndex + ", record=" + JSON.stringify(this.record)
      );
      if (recordIndex > 0) {
        this.getMemberIdOptions();
      }
    },
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      //   this.$refs.ProfilePicture.reset();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      console.log(this.ResultFlag);
      this.$emit("hideEditRecordPrompt", this.ResultFlag);
    },
    getMemberDetails() {
      console.log("getMemberDetails called");
      var temp_code = this.MemberId;
      console.log("temp_code=" + temp_code);
      var idx = this.MemberIdOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var member = this.MemberIdOptions[idx];
        console.log("member=" + JSON.stringify(member));
        this.member = member;
      }
    },
    getJciYearCodeOptions() {
      this.LoadingFlag = true;
      this.JciYearCodeOptionsLoading = true;
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getAreaCodeOptions() {
      console.log("getAreaCodeOptions called");
      this.AreaCodeOptionsLoading = true;
      var selectbox1_source = "AreaCode";
      var selectbox1_destination = "AreaCodeOptions";
      var selectbox1_url = "api/area/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getRegionCodeOptions() {
      console.log("getRegionCodeOptions called");
      this.RegionCodeOptionsLoading = true;
      var selectbox1_source = "RegionCode";
      var selectbox1_destination = "RegionCodeOptions";
      var selectbox1_url = "api/region/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getMemberIdOptions() {
      console.log("getMemberIdOptions called");
      this.MemberIdOptionsLoading = true;
      var selectbox1_source = "MemberId";
      var selectbox1_destination = "MemberIdOptions";
      var selectbox1_url = "api/members/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        LomCode: this.recordIndex,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        this.toast("error", "Kindly fill the required fields", true);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      //   validate1 = false;

      if (validate1) {
        this.SubmitFlag = true;

        var Members = [
          {
            YearId: this.JciYearCode,
            YearWiseLomId: this.record.YearwiseLomId,
            LomId: this.recordIndex,
            MemberId: this.MemberId,
            MemberName: this.member.member_name,
            DesignationId: 4,
            DateOfBirth: this.member.member_dob,
          },
        ];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/assign-designation";
        var upload = {
          UserInterface: 1,
          YearId: this.JciYearCode,
          LomId: this.recordIndex,
          YearWiseLomId: this.record.YearwiseLomId,
          AreaCode: this.AreaCode,
          RegionCode: this.RegionCode,
          Members: Members,
          Reason: this.Reason,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.resetForm();
              thisIns.ResultFlag = true;
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        thisIns.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
